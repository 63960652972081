@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  html{
    font-family: 'Poppins', sans-serif;
  }
  body{
    @apply bg-black-100;
    @apply text-black-500;
    @apply text-xs sm:text-sm lg:text-md;
  }
  label{
    @aply text-xs sm:text-sm;
    @apply mb-2;
  }
  th{
    @apply py-3;
    @apply px-2;
  }
  td{
    @apply p-2;
  }
  tr{
    @apply border-b;
    @apply border-black-200;
  }
  tbody{
    @apply text-xs sm:text-sm;
  }
}
